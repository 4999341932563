import { ref, computed, reactive, shallowReactive, type Ref } from "vue";
import { acceptHMRUpdate, defineStore } from "pinia";
import type { User } from "@/models";
import API from "@/api";
import { TrackCalulator } from "@/scripts/Configurator/TrackCalculator";

export const useUserStore = defineStore("user", () => {
	const user = ref() as Ref<User>;
	const isLoggedIn = ref(localStorage.getItem("loggedIn") == "true");
	const selectedClient = ref(localStorage.getItem("selectedClient"));

	async function loadUser(): Promise<User> {
		await API.get<User>("api/v1/user/me").then((x) => (user.value = x.data));

		return user.value;
	}

	function setSelectedClient(client_id?: number) {
		if (!client_id) {
			localStorage.removeItem("selectedClient");
			return;
		}
		localStorage.setItem("selectedClient", client_id.toString());

		selectedClient.value = client_id.toString();
	}

	async function login(email: string, password: string, remember = false): Promise<string | undefined> {
		await API.raw().get("/sanctum/csrf-cookie");
		await API.raw().post("/login", {
			email,
			password,
			remember,
		});
		localStorage.setItem("loggedIn", "true");

		await loadUser();

		return undefined;
	}

	async function logout() {
		isLoggedIn.value = false;
		user.value = undefined as unknown as User;
		localStorage.clear();

		return API.raw().post("/logout");
	}

	return {
		user,
		isLoggedIn,
		logout,
		login,
		loadUser,
		selectedClient,
		setSelectedClient,
	};
});
