<template>
	<div id="footer" class="w-full justify-between border-t px-4 py-2 text-center sm:flex lg:pl-64 lg:pr-12">
		<p class="text-xs text-secondary-600">Build: {{ buildTs || "local" }}, Id: {{ commitId || "local" }}</p>
		<p class="text-xs text-secondary-600">All rights reserved. &copy; {{ dayjs().format("YYYY") }} MOOS International BV</p>
	</div>
</template>

<script setup lang="ts">
import dayjs from "dayjs";

const buildTs = import.meta.env.VITE_BUILD_TS;
const commitId = import.meta.env.VITE_COMMIT_ID;
</script>
