import { useUserStore } from "./stores/user";

export function hasPermission(value: string) {
	const permissions = useUserStore().user?.permissions;
	if (!permissions) return false;
	if (!value) return useUserStore().user?.roles.find((x) => x == "moos:admin");

	let _return = false;
	if (!Array.isArray(permissions)) {
		return false;
	}
	if (value.includes("|")) {
		value.split("|").forEach(function (item) {
			if (permissions.includes(item.trim())) {
				_return = true;
			}
		});
	} else if (value.includes("&")) {
		_return = true;
		value.split("&").forEach(function (item) {
			if (!permissions.includes(item.trim())) {
				_return = false;
			}
		});
	} else {
		_return = permissions.includes(value.trim());
	}
	return _return || useUserStore().user?.roles.find((x) => x == "moos:admin");
}

export function isRole(value: string) {
	const roles = useUserStore().user?.roles;

	if (!value) return roles.find((x) => x == "moos:admin");
	if (!roles) return false;
	let _return = false;
	if (!Array.isArray(roles)) {
		return false;
	}
	if (value.includes("|")) {
		value.split("|").forEach(function (item) {
			if (roles.includes(item.trim())) {
				_return = true;
			}
		});
	} else if (value.includes("&")) {
		_return = true;
		value.split("&").forEach(function (item) {
			if (!roles.includes(item.trim())) {
				_return = false;
			}
		});
	} else {
		_return = roles.includes(value.trim());
	}
	return _return || roles.find((x) => x == "moos:admin");
}

export function isClient() {
	const roles = useUserStore().user?.roles;

	return roles.every((item) => item.startsWith("client:"));
}

export function isMyself(userId: number) {
	const myself = useUserStore().user;

	return userId == myself.id;
}

export function isMyClient(clientId: number) {
	const myClient = useUserStore().selectedClient;

	return clientId.toString() == myClient;
}

export default {
	install: (app: any) => {
		app.config.globalProperties.can = hasPermission;
		app.config.globalProperties.is = isRole;
	},
};
