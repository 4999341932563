<template>
	<div v-if="navItems">
		<!-- [XG] Sidebar for mobile (opens on click) -->
		<TransitionRoot as="template" :show="sidebarOpen" v-if="show">
			<Dialog as="div" id="sidebar" class="relative z-50 lg:hidden" @close="sidebarOpen = false">
				<TransitionChild
					as="template"
					enter="transition-opacity ease-linear duration-300"
					enter-from="opacity-0"
					enter-to="opacity-100"
					leave="transition-opacity ease-linear duration-300"
					leave-from="opacity-100"
					leave-to="opacity-0"
				>
					<div class="fixed inset-0 bg-secondary-700/30" />
				</TransitionChild>

				<div class="fixed inset-0 flex">
					<TransitionChild
						as="template"
						enter="transition ease-in-out duration-300 transform"
						enter-from="-translate-x-full"
						enter-to="translate-x-0"
						leave="transition ease-in-out duration-300 transform"
						leave-from="translate-x-0"
						leave-to="-translate-x-full"
					>
						<DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
							<TransitionChild
								as="template"
								enter="ease-in-out duration-300"
								enter-from="opacity-0"
								enter-to="opacity-100"
								leave="ease-in-out duration-300"
								leave-from="opacity-100"
								leave-to="opacity-0"
							>
								<div class="absolute left-full top-0 flex w-16 justify-center pt-5">
									<button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
										<span class="sr-only">Close sidebar</span>
										<XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
									</button>
								</div>
							</TransitionChild>

							<!-- Sidebar component, swap this element with another sidebar if you like -->
							<div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
								<RouterLink :to="navItems[0]?.path" @click="sidebarOpen = false">
									<div class="flex h-16 shrink-0 items-center">
										<img src="/logo.svg" class="h-16 w-auto" alt="MOOS Logo" />
									</div>
								</RouterLink>

								<SideBarList :navItems="navItems" @clicked="sidebarOpen = false"></SideBarList>
							</div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</Dialog>
		</TransitionRoot>
		<!-- Sidebar for desktop -->
		<div id="sidebar" class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-60 lg:flex-col" v-if="show">
			<div class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-secondary-200 bg-white px-6 pb-4">
				<RouterLink :to="'/'" @click="sidebarOpen = false">
					<div class="flex h-16 shrink-0 items-center justify-center">
						<img src="/logo.svg" class="h-16 w-auto" alt="MOOS Logo" />
					</div>
				</RouterLink>
				<SideBarList :navItems="navItems"></SideBarList>
			</div>
		</div>

		<!-- [XG] Navbar (top) -->
		<div :class="show && 'lg:pl-60'">
			<div
				v-if="show"
				id="navbar"
				class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-secondary-200 bg-white/20 px-4 backdrop-blur-md backdrop-filter sm:gap-x-6 sm:px-6 lg:px-8"
			>
				<button type="button" class="-m-2.5 p-2.5 text-secondary-700 lg:hidden" @click="sidebarOpen = true">
					<span class="sr-only">Open sidebar</span>
					<Bars3Icon class="h-6 w-6" aria-hidden="true" />
				</button>

				<!-- Separator -->
				<div class="h-6 w-px bg-secondary-200 lg:hidden" aria-hidden="true" />

				<div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
					<!-- [XG] Search disabled for the time being -->
					<div class="relative flex flex-1"></div>
					<!-- <form class="relative flex flex-1" action="#" method="GET">
						<label for="search-field" class="sr-only">Search</label>
						<MagnifyingGlassIcon class="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-secondary-400" aria-hidden="true" />
						<input id="search-field" class="block h-full w-full border-0 py-0 pl-8 pr-0 text-secondary-900 placeholder:text-secondary-400 focus:ring-0 sm:text-sm" placeholder="Search..." type="search" name="search" />
					</form> -->
					<div class="flex items-center gap-x-4 lg:gap-x-6">
						<div v-if="isRole('moos:admin') && clients">
							<BasicSelect
								v-model="selectedClient"
								@change="
									() => {
										userStore.setSelectedClient(selectedClient?.id);
										$emit('clientChange');
									}
								"
							>
								<option v-for="client in clients" :key="client.id" :value="client">
									{{ client.title }}
								</option>
							</BasicSelect>
						</div>

						<!-- [OK] Disabled as there are no notifications yet. -->
						<!-- <NotificationsPopover /> -->

						<!-- Separator -->
						<div class="hidden lg:block lg:h-6 lg:w-px lg:bg-secondary-200" aria-hidden="true" />

						<!-- Profile dropdown -->
						<Menu as="div" class="relative">
							<MenuButton
								class="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
							>
								<span class="sr-only">Open user menu</span>
								<img v-if="userAvatar" class="h-8 w-8 rounded-full bg-white" :src="userAvatar" />
								<div v-else class="h-8 w-8 animate-pulse rounded-full bg-secondary-50"></div>
							</MenuButton>

							<transition
								enter-active-class="transition ease-out duration-100"
								enter-from-class="transform opacity-0 scale-95"
								enter-to-class="transform opacity-100 scale-100"
								leave-active-class="transition ease-in duration-75"
								leave-from-class="transform opacity-100 scale-100"
								leave-to-class="transform opacity-0 scale-95"
							>
								<MenuItems
									class="absolute right-0 z-10 mt-5 w-52 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
								>
									<div class="border-b p-2 px-4">
										<h3 class="text-lg font-bold text-secondary-800">{{ userStore.user.name }}</h3>
										<p class="text-sm text-secondary-500">{{ userStore.user.email }}</p>
									</div>
									<MenuItem v-slot="{ active, close }">
										<a
											@click.prevent="
												router.push('/settings');
												close();
											"
											:href="'/settings'"
											:class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
											>Settings</a
										>
									</MenuItem>
									<MenuItem v-slot="{ active }" v-if="isRole('moos:admin')">
										<a
											:href="appUrl == 'https://staging.app.moos.nu' ? 'https://app.moos.nu' : 'https://staging.app.moos.nu'"
											:class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
											>Switch to {{ appUrl == "https://staging.app.moos.nu" ? "production" : "staging" }}</a
										>
									</MenuItem>
									<MenuItem v-slot="{ active }">
										<a href="#" @click="logout()" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
											>Sign out</a
										>
									</MenuItem>
								</MenuItems>
							</transition>
						</Menu>
					</div>
				</div>
			</div>
			<main class="my-4">
				<div class="container mx-auto space-y-4 px-4 py-4 sm:px-6 md:p-4 lg:px-8" :class="$route.meta.width ?? 'max-w-6xl'">
					<slot></slot>
				</div>
			</main>
		</div>
	</div>
</template>

<script setup lang="ts">
import { hasPermission, isRole } from "@/permissions";
import router from "@/router";
import { useUserStore } from "@/stores/user";
import {
	Dialog,
	DialogPanel,
	Disclosure,
	DisclosureButton,
	DisclosurePanel,
	Menu,
	MenuButton,
	MenuItem,
	MenuItems,
	TransitionChild,
	TransitionRoot,
} from "@headlessui/vue";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { ChevronDownIcon, ChevronRightIcon, MagnifyingGlassIcon } from "@heroicons/vue/20/solid";
import { computed, onMounted, watch, watchEffect } from "vue";
import { ref } from "vue";
import { useRouter, type RouteRecordNormalized } from "vue-router";
import md5 from "md5";
import API from "@/api";
import type { Client, Notification, SidebarItem } from "@/models";
import dayjs from "dayjs";

const props = defineProps<{
	show: boolean;
}>();

const userStore = useUserStore();
const routes = router.getRoutes();

const appUrl = import.meta.env.VITE_APP_URL;

const navItems = computed(() => {
	if (!userStore.user) return [];

	let markedRoutes: SidebarItem[] = [];

	for (const route of router.options.routes) {
		// Check the top-level route
		if (
			route.meta?.show === "sidebar" &&
			(route.meta.role === "any" || isRole(route.meta.role as string) || hasPermission(route.meta.permission as string))
		) {
			markedRoutes.push({ ...route, isParent: true });
		}

		// Check child routes (if they exist)
		// if (route.children) {
		// 	for (const childRoute of route.children) {
		// 		if (childRoute.meta.show === "sidebar" &&
		// 			(childRoute.meta.role === "any" || isRole(childRoute.meta.role as string) || hasPermission(childRoute.meta.permission as string))) {
		// 			// markedRoutes.push({ ...childRoute, parentPath: route.path, isParent: false });
		// 		}
		// 	}
		// }
	}
	return markedRoutes;
});

const selectedClient = ref<Client>();
const clients = ref<Client[]>();

watchEffect(() => {
	if (userStore.user && isRole("moos:admin")) {
		API.get<Client[]>("/api/v1/clients?limit=1000").then((x) => {
			x.data.unshift({
				id: -1,
				title: "-- All Clients --",
				token: "000",
				type: "client",
				created_at: null,
				updated_at: null,
				deleted_at: null,
			});

			if (userStore.selectedClient) {
				selectedClient.value = x.data.find((x) => x.id == parseInt(userStore.selectedClient || "0"));
			} else {
				selectedClient.value = x.data[1];
			}
			clients.value = x.data;
		});
	}
});

const userAvatar = computed(() => {
	if (!userStore.user) return;
	const address = String(userStore.user.email).trim().toLowerCase();

	// Create an MD5 hash of the final string
	const hash = md5(address);

	// Grab the actual image URL
	return `https://www.gravatar.com/avatar/${hash}`;
});

const sidebarOpen = ref(false);

async function logout() {
	await userStore.logout();

	router.push("/login");
}
</script>
