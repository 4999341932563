<template>
	<div>
		<label v-if="label" :for="label" class="flex text-sm font-medium text-secondary-700">
			{{ label }}
			<p v-if="required === true" class="px-1 text-red-700">*</p>
		</label>
		<select
			:id="label"
			v-model="mval"
			:required="required"
			@change="
				$emit('update:modelValue', mval as any);
				$emit('change', $event);
			"
			class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
		>
			<option v-if="placeholder !== undefined" :value="undefined" disabled selected hidden>{{ placeholder }}</option>
			<slot></slot>
		</select>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";

export default defineComponent({
	data() {
		return {
			mval: this.modelValue,
		};
	},
	props: {
		label: {
			type: String,
		},
		required: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: undefined,
		},
		showPlaceholder: {
			type: Boolean,
			default: false,
		},
		modelValue: [Object, String, Number],
		value: String,
	},
	emits: ["update:modelValue", "change"],
});
</script>
