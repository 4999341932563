import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";

import "./assets/index.css";
import permissions from "./permissions";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import { vue3Debounce } from "vue-debounce";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import VueApexCharts from "vue3-apexcharts";
import "v-network-graph/lib/style.css";
import VNetworkGraph from "v-network-graph";
import { BrowserAgent } from "@newrelic/browser-agent/loaders/browser-agent";

const app = createApp(App);

app.use(permissions);
app.use(createPinia());
app.use(router);
app.use(Toast);
app.use(VueApexCharts);
app.use(VNetworkGraph);

if (import.meta.env.VITE_APP_URL != "http://localhost:3001") {
	new BrowserAgent({
		init: {
			distributed_tracing: { enabled: true },
			privacy: { cookies_enabled: true },
			ajax: { deny_list: [import.meta.env.VITE_NEWRELIC_BEACON] },
		},
		info: {
			beacon: import.meta.env.VITE_NEWRELIC_BEACON,
			errorBeacon: import.meta.env.VITE_NEWRELIC_ERROR_BEACON,
			licenseKey: import.meta.env.VITE_NEWRELIC_APPLICATION_KEY,
			applicationID: import.meta.env.VITE_NEWRELIC_APPLICATION_ID,
			sa: 1,
		},
		loader_config: {
			accountID: import.meta.env.VITE_NEWRELIC_ACCOUNT_ID,
			trustKey: import.meta.env.VITE_NEWRELIC_TRUST_KEY,
			agentID: import.meta.env.VITE_NEWRELIC_AGENT_ID,
			licenseKey: import.meta.env.VITE_NEWRELIC_APPLICATION_KEY,
			applicationID: import.meta.env.VITE_NEWRELIC_APPLICATION_ID,
		},
	});
}

dayjs.extend(relativeTime);
app.directive("debounce", vue3Debounce({ lock: true }));

app.mount("#app");
