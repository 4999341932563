import { hasPermission, isRole } from "@/permissions";
import { useUserStore } from "@/stores/user";
import { h } from "vue";
import { createRouter, createWebHistory, type RouteLocationNormalized } from "vue-router";

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			name: "Dashboard",
			path: "/",
			meta: {
				show: "sidebar",
				description: "Welcome to your MOOS dashboard.",
				order: 0,
				width: "max-w-7xl",
				role: "",
				permission: "dashboard",
				breadcrumb: [{ name: "Dashboard", link: "/" }],
			},
			component: () => import("@/views/HomePage.vue"),
		},
		{
			name: "Analytics",
			path: "/analytics/:location_id?/:installation_id?",
			meta: {
				show: "sidebar",
				description: "A place to analyze your fleet's data.",
				order: 1,
				width: "",
				permission: "analytics",
				breadcrumb: [{ name: "Dashboard", link: "/" }, { name: "Analytics" }],
			},
			/*
			 * [XG][TODO] What should this redirect to? Does it need an installation id?
			 * Or an in-between page to select an installation?
			 */
			component: () => import("@/views/Analytics/InstallationView.vue"),
		},
		{
			name: "Analyzing trackgroups",
			path: "/analytics/track_group/:group_id",
			meta: {
				show: "",
				permission: "analytics",
				breadcrumb: [{ name: "Dashboard", link: "/" }, { name: "Analytics", link: "/analytics" }, { name: "Product group" }],
			},
			component: () => import("@/views/Analytics/TrackGroupView.vue"),
		},
		{
			name: "Analyzing track",
			path: "/analytics/track/:track_id",
			meta: {
				show: "",
				permission: "analytics",
				breadcrumb: [{ name: "Dashboard", link: "/" }, { name: "Analytics", link: "/analytics" }, { name: "Track" }],
			},
			component: () => import("@/views/Analytics/TrackPage.vue"),
		},
		{
			name: "Shopping list",
			path: "/location/:location_id/shoppinglist",
			meta: {
				show: "",
				description: "",
				permission: "any",
				breadcrumb: [{ name: "Dashboard", link: "/" }, { name: "Shopping list" }],
			},
			component: () => import("@/views/Tools/ShoppingList/ShoppingList.vue"),
		},
		{
			name: "Fleet",
			path: "/fleet",
			redirect: "/fleet/configurator",
			meta: {
				container: true,
				show: "sidebar",
				order: 10,
				permission: "fleetmgr|gatewaymgr|productmgr|configurator",
				breadcrumb: [{ name: "Dashboard", link: "/" }, { name: "Setup" }],
			},
			children: [
				{
					name: "Configurator",
					path: "configurator",
					meta: {
						show: "sidebar",
						description: "A place to configure your fleet's shelves.",
						order: 11,
						permission: "configurator",
						breadcrumb: [{ name: "Dashboard", link: "/" }, { name: "Configurator" }],
					},
					component: () => import("@/views/Fleets/Configurator/ConfiguratorPage.vue"),
					children: [
						{
							name: "Select installation and shelf",
							path: "",
							meta: {
								show: "",
								permission: "configurator",
								breadcrumb: [{ name: "Dashboard", link: "/" }, { name: "Configurator" }],
							},
							component: () => import("@/views/Fleets/Configurator/HomePage.vue"),
						},
						{
							name: "Configurator",
							path: ":shelf_id",
							meta: {
								show: "",
								permission: "configurator",
								breadcrumb: [{ name: "Dashboard", link: "/" }, { name: "Configurator" }],
							},
							component: () => import("@/views/Fleets/Configurator/ConfigurationScreen.vue"),
						},
					],
				},
				{
					name: "Sensors",
					path: "sensors",
					component: () => import("@/views/Fleets/Manager/SensorOverviewPage.vue"),
					meta: {
						show: "sidebar",
						description: "A list of all the sensors in your fleet including their different Id's, type, status and location.",
						order: 11,
						permission: "fleetmgr",
						breadcrumb: [{ name: "Dashboard", link: "/" }, { name: "Sensors" }],
					},
				},
				{
					name: "Sensor",
					path: "sensors/:sensorId",
					component: () => import("@/views/Fleets/Manager/SensorPage.vue"),
					meta: {
						show: "",
						permission: "fleetmgr",
						breadcrumb: [{ name: "Dashboard", link: "/" }, { name: "Sensors", link: "/fleet/sensors" }, { name: "Sensor" }],
					},
				},
				{
					name: "Products",
					path: "products",
					component: () => import("@/views/Fleets/Manager/ProductOverviewPage.vue"),
					meta: {
						show: "sidebar",
						description: "A place to add, delete or edit products for your store.",
						order: 12,
						permission: "productmgr",
						breadcrumb: [{ name: "Dashboard", link: "/" }, { name: "Products" }],
					},
				},
				{
					name: "Product",
					path: "products/:productId",
					component: () => import("@/views/Fleets/Manager/ProductPage.vue"),
					meta: {
						show: "",
						permission: "productmgr",
						breadcrumb: [{ name: "Dashboard", link: "/" }, { name: "Products", link: "/fleet/products" }, { name: "Product" }],
					},
				},
				{
					name: "Gateways",
					path: "gateways",
					component: () => import("@/views/Fleets/Manager/GatewayOverviewPage.vue"),
					meta: {
						show: "sidebar",
						description: "A list of all the gateways in your fleet including their different Id's, type, status and location.",
						order: 13,
						permission: "gatewaymgr",
						breadcrumb: [{ name: "Dashboard", link: "/" }, { name: "Gateways" }],
					},
				},
				{
					name: "Gateway",
					path: "gateways/:gatewayId",
					meta: {
						show: "",
						permission: "gatewaymgr",
						breadcrumb: [{ name: "Dashboard", link: "/" }, { name: "Gateways", link: "/fleet/gateways" }, { name: "Gateway" }],
					},
					component: () => import("@/views/Fleets/Manager/GatewayPage.vue"),
				},
				{
					name: "Installation",
					path: "installations/:installationId",
					meta: {
						show: "",
						permission: "configurator",
						breadcrumb: [{ name: "Dashboard", link: "/" }, { name: "Gateways", link: "/fleet/installations" }, { name: "Installation" }],
					},
					component: () => import("@/views/Fleets/Manager/InstallationPage.vue"),
				},
				{
					name: "Shelves",
					path: "shelves",
					component: () => import("@/views/Fleets/Manager/ShelfOverviewPage.vue"),
					meta: {
						show: "sidebar",
						description: "A list of all the Shelves in your fleet including their different Id's, type, status and location.",
						order: 14,
						permission: "shelvesmgr",
						breadcrumb: [{ name: "Dashboard", link: "/" }, { name: "Shelves" }],
					},
				},
				{
					name: "Shelf",
					path: "shelves/:shelfId",
					meta: {
						show: "",
						permission: "shelvesmgr",
						breadcrumb: [{ name: "Dashboard", link: "/" }, { name: "Shelves", link: "/fleet/shelves" }, { name: "Shelf" }],
					},
					component: () => import("@/views/Fleets/Manager/ShelfPage.vue"),
				},
			],
		},
		{
			name: "Tools",
			path: "/tools",
			redirect: "/tools/stock",
			meta: {
				container: true,
				show: "sidebar",
				order: 20,
				permission: "stock|operations|communicator",
				breadcrumb: [{ name: "Dashboard", link: "/" }, { name: "Tools" }],
			},
			//component: () => import("@/views/Tools/LandingPage.vue"),
			children: [
				{
					name: "Stock Insights",
					path: "stock",
					meta: {
						show: "sidebar",
						order: 21,
						permission: "stock",
						breadcrumb: [{ name: "Dashboard", link: "/" }, { name: "Tools", link: "/tools" }, { name: "Stock Insights" }],
					},
					component: () => import("@/views/Tools/Stock/LocationSelector.vue"),
				},
				{
					name: "Stock",
					path: "stock/:location/:installation?",
					meta: {
						show: "",
						permission: "stock",
						breadcrumb: [{ name: "Dashboard", link: "/" }, { name: "Tools", link: "/tools" }, { name: "Stock Insights" }],
					},
					component: () => import("@/views/Tools/Stock/LocationViewer.vue"),
				},
				{
					name: "Communicator",
					path: "communicator",
					meta: {
						show: "sidebar",
						order: 25,
						description: "Send messages to your fleet.",
						permission: "operations",
						breadcrumb: [{ name: "Dashboard", link: "/" }, { name: "Tools", link: "/tools" }, { name: "Communicator" }],
					},
					component: () => import("@/views/Tools/Communicator/CommunicatorView.vue"),
				},
				{
					name: "Collection",
					path: "collection",
					redirect: "/tools/collection/collect",
					meta: {
						show: "sidebar",
						order: 31,
						permission: "collection",
						breadcrumb: [{ name: "Dashboard", link: "/" }],
					},
					// component: () => import("@/views/Collection/LandingPage.vue"),
					children: [
						{
							name: "Select session",
							path: "collect",
							meta: {
								show: "",
								permission: "collection",
								breadcrumb: [{ name: "Dashboard", link: "/" }],
							},
							component: () => import("@/views/Collection/Collect/SessionSelector.vue"),
						},
						{
							name: "Collect",
							path: "collect/:id",
							meta: {
								show: "",
								group: "tools",
								permission: "collection",
								breadcrumb: [{ name: "Dashboard", link: "/" }],
							},
							component: () => import("@/views/Collection/Collect/SessionCollector.vue"),
						},
						{
							name: "Admin Panel",
							path: "admin",
							meta: {
								group: "tools",
								show: "",
								permission: "collection",
								breadcrumb: [{ name: "Dashboard", link: "/" }],
							},
							children: [
								{
									path: "",
									name: "Home",
									meta: {
										group: "tools",
										show: "",
										permission: "collection",
										breadcrumb: [{ name: "Dashboard", link: "/" }],
									},
									component: () => import("@/views/Collection/Admin/HomePage.vue"),
								},
								{
									path: "sessions",
									name: "Sessions",
									meta: {
										group: "tools",
										show: "",
										permission: "collection",
										breadcrumb: [{ name: "Dashboard", link: "/" }],
									},
									component: () => import("@/views/Collection/Admin/SessionsPage.vue"),
								},
								{
									path: "users",
									name: "Users",
									meta: {
										show: "",
										permission: "collection",
										breadcrumb: [{ name: "Dashboard", link: "/" }],
									},
									component: () => import("@/views/Collection/Admin/UsersPage.vue"),
								},
								{
									path: "qualitycheck",
									name: "Quality Check",
									meta: {
										group: "tools",
										show: "",
										permission: "collection",
										breadcrumb: [{ name: "Dashboard", link: "/" }],
									},
									component: () => import("@/views/Collection/Admin/QualityCheck.vue"),
									children: [
										{
											path: ":id",
											name: "QC checker",
											meta: {
												group: "tools",
												show: "",
												permission: "collection",
												breadcrumb: [{ name: "Dashboard", link: "/" }],
											},
											component: () => import("@/views/Collection/Admin/QualityChecker.vue"),
										},
									],
								},
							],
							component: () => import("@/views/Collection/Admin/AdminPage.vue"),
						},
					],
				},
			],
		},
		{
			name: "Organization",
			path: "/organization",
			redirect: "/organization/users",
			meta: {
				container: true,
				show: "sidebar",
				order: 50,
				permission: "usermgr",
				breadcrumb: [{ name: "Dashboard", link: "/" }, { name: "Setup" }],
			},
			children: [
				{
					name: "Clients",
					path: "clients",
					meta: {
						show: "sidebar",
						description: "Manage clients.",
						permission: "clientmgr",
						order: 51,
						breadcrumb: [{ name: "Dashboard", link: "/" }, { name: "Organization" }, { name: "Clients" }],
					},
					component: () => import("@/components/UserManagementPage/ClientManagement.vue"),
				},
				{
					name: "Users",
					path: "users",
					meta: {
						show: "sidebar",
						description: "Manage your users.",
						permission: "usermgr",
						order: 52,
						breadcrumb: [{ name: "Dashboard", link: "/" }, { name: "Organization" }, { name: "Users" }],
					},
					component: () => import("@/components/UserManagementPage/UserManagement.vue"),
				},
				{
					name: "Alerts",
					path: "alerts",
					meta: {
						show: "sidebar",
						description: "Manage your installation's alerts.",
						order: 53,
						permission: "alertmgr",
						breadcrumb: [{ name: "Dashboard", link: "/" }, { name: "Organization" }, { name: "Alerts" }],
					},
					component: () => import("@/components/UserManagementPage/AlertManager.vue"),
				},
				{
					name: "Roles & Permissions",
					path: "roles",
					meta: {
						show: "sidebar",
						description: "Manage the roles and permissions of your users.",
						order: 54,
						role: "moos:admin",
						breadcrumb: [{ name: "Dashboard", link: "/" }, { name: "Organization" }, { name: "Roles & Permissions" }],
					},
					component: () => import("@/views/Fleets/Manager/RoleManagementPage.vue"),
				},
			],
		},
		{
			name: "Settings",
			path: "/settings",
			meta: {
				show: "",
				role: "any",
				breadcrumb: [{ name: "Settings", link: "settings/" }],
			},
			component: () => import("@/views/UserSettingsPage.vue"),
		},
		{
			name: "Login",
			path: "/login",
			meta: {
				show: "",
				role: "any",
				breadcrumb: [{ name: "Dashboard", link: "/" }],
			},
			component: () => import("@/views/LoginPage.vue"),
		},
		{
			name: "Forgot password",
			path: "/forgot-password",
			meta: {
				show: "",
				role: "*",
				breadcrumb: [{ name: "Dashboard", link: "/" }],
			},
			component: () => import("@/views/ForgotPassword.vue"),
		},
		{
			name: "Reset password",
			path: "/reset-password",
			meta: {
				show: "",
				role: "*",
				breadcrumb: [{ name: "Dashboard", link: "/" }],
			},
			component: () => import("@/views/ResetPassword.vue"),
		},
		{
			path: "/:pathMatch(.*)*",
			name: "Not Found",
			meta: {
				show: "",
				role: "any",
			},
			component: import("@/views/NotFoundPage.vue"),
		},
	],
});

// router.beforeResolve(to => {

// })

router.beforeEach((to, from, next) => {
	window.document.title = (to.name as string) + " | MOOS";

	next();
});

router.beforeResolve(async (to, from) => {
	const userStore = useUserStore();

	if (to.meta.role == "*") {
		return true;
	}

	// console.log(userStore.user, userStore.isLoggedIn);
	if (userStore.isLoggedIn && userStore.user == undefined) {
		try {
			await userStore.loadUser();
		} catch (err) {
			console.error(err);
			userStore.logout();
			return { name: "Login", query: { redirect: to.path } };
		}
	}

	if (to.meta.role != "any" && userStore.user == undefined) {
		return { name: "Login", query: { redirect: to.path } };
	} else if (
		to.meta.role &&
		to.meta.role != "any" &&
		!isRole(to.meta.role as string) &&
		!hasPermission(to.meta.permission as string) &&
		!isRole("admin")
	) {
		return { name: "Permission denied" };
	}
});

export default router;
