<template>
	<Suspense>
		<template #default>
			<div id="app_wrap" class="flex min-h-screen flex-col justify-between antialiased">
				<SideBar @clientChange="renderKey++" :show="$route.path !== '/login' && $route.meta.role != '*' && userStore.user != undefined">
					<RouterView :key="renderKey"></RouterView>
				</SideBar>

				<FooterBar v-if="userStore.user" v-show="$route.path != '/login'" />
			</div>
		</template>
		<template #fallback>
			<LoadingSpinner class="mx-auto my-96" />
		</template>
	</Suspense>
</template>

<script setup lang="ts">
import { useUserStore } from "@/stores/user";
import { onMounted, ref, watch, watchEffect } from "vue";

const userStore = useUserStore();

const renderKey = ref(0);
</script>

<style lang="css">
.Vue-Toastification__toast-body {
	@apply text-sm;
}

.Vue-Toastification__toast.top-right {
	@apply flex min-h-[3.5rem] items-center bg-gradient-to-tr p-0 px-4 py-2 font-sans text-sm font-semibold;
}

.Vue-Toastification__toast.top-right svg {
	@apply my-0;
}

.Vue-Toastification__toast.Vue-Toastification__toast--default {
	@apply from-blue-400 to-blue-500;
}

.Vue-Toastification__toast.Vue-Toastification__toast--error {
	@apply from-red-400 to-red-600;
}

.Vue-Toastification__toast.Vue-Toastification__toast--warning {
	@apply from-orange-400 to-orange-500;
}

.Vue-Toastification__toast.Vue-Toastification__toast--success {
	@apply from-green-500 to-green-600;
}

.Vue-Toastification__toast.Vue-Toastification__toast--default {
	@apply from-blue-400 to-blue-500;
}

.Vue-Toastification__close-button {
	margin-top: -0.25rem !important;
}
</style>
